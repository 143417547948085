import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { pageMetadataMap, PageType } from '@/shared/app-common';
// import { HomeArticleModel } from '@/types/home';

const LoginStateInfo = ({ pageType }: { pageType?: PageType }) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const pageMetaData = pageMetadataMap[pageType ?? PageType.Normal];
  const onDashboardClick = () => {
    router.push(`/dashboard?alertType=1`);
    ga_logEvent(EventName.home_ADD_ACCOUNT);
  };

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h1>
          {` ${t(pageMetaData.title1Key)} `}
          <br />
          {<div className={styles.tracker}>{t(pageMetaData.title2Key)}</div>}
        </h1>

        <p>{t(pageMetaData.titleDescKey)}</p>

        <Link className={styles.seeAnalyticsSample} href="/instagram-tracking-example">
          {t('SeeAnalyticsSample')}
        </Link>
        <span className={styles.goToDashboard} onClick={onDashboardClick}>
          {t('ADD ACCOUNT')}
        </span>
      </div>
    );
  }, [pageMetaData, onDashboardClick]);

  return <>{getContent}</>;
};

export default LoginStateInfo;
